import React from 'react'
import { SEO, Layout } from '../components'
import PageHeader from '../components/page-header'
import {Link} from 'gatsby'
const Hakkimizda = () => {
  return (
    <Layout>
      <SEO title="Hakkimizda" description="Timurlar Çatalca Sigorta olarak 7/24 uygun sigorta fiyatlarıyla yanınızdayız." />
      <PageHeader>
        <h1>Hakkimizda</h1>
        <p>
            Taleplerinizi, isteklerinizi ve
            beklentilerinizi önemsiyoruz.
        </p>
        <Link className="btn btn-banner" to="/teklif-al">
          Hemen Teklif Al
        </Link>
      </PageHeader>
      <div className="page-content">
        <div className="container">
          <p className="hero">
           Bu doğrultuda değerli müşterilerimizin
            tercihleri doğrultusunda hizmet vermek adına empati kuruyor,
            kendimizi müşterilerimizin yerine koyuyoruz. Kaliteli, hızlı ve
            aktif hizmet başta olmak üzere; müşterilerinin istediğini sunan bu
            sayede memnun eden bir sigortacılık mottosu ile hareket ediyoruz.
          </p>
            <p>
                Timurlar Çatalca Sigorta olarak, tüm sigortalılarımız ile uzun
                soluklu çalışmayı hedefliyoruz. Sadece poliçe öncesi satış aşamasını
                değil, poliçe sonrası hasar gibi birçok sorun kısmında da
                faaliyetimizi 7/24 tüm Türkiye genelinde sürdürüyoruz. Bu sayede
                sigortalılarımızın her anında yanında olarak çözüm odaklı bir
                yaklaşım ile kalıcı bir işbirliğini sağlamış oluyoruz. Sonuç olarak
                mutlu ve memnun müşteri, sektörde kalıcı ve güvenilir sigortacılık.</p>
        </div>
      </div>
    </Layout>
  )
}

export default Hakkimizda
